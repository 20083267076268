import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'

interface IPasswordFormRender {
    onSubmit: (values: any) => void
    includeExisting?: boolean
}

export const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
}
export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
}

const ChangePasswordForm = ({ onSubmit, includeExisting = true }: IPasswordFormRender) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const onFinish = async (values: any) => {
        setLoading(true)
        await onSubmit(values)
        setLoading(false)
    }

    return (
        <>
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >
                {includeExisting && (
                    <Form.Item
                        name="existingPassword"
                        label="Existing Password"
                        rules={[
                            {
                                required: includeExisting,
                                message: 'Please input your current password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                )}
                <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                        {
                            required: true,
                            min: 8,
                            message: 'Password must be at least 8 characters!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your new password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(
                                    'The two passwords that you entered do not match!'
                                )
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Change
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default ChangePasswordForm
