import { Button, Form, message, Modal } from 'antd'
import React from 'react'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { httpPostRevisor } from '../../services/http'
import ChangePasswordForm from '../Settings/change-password.form'

const ResetPassword = () => {
    let location = useLocation()

    const error = (message: string) => {
        Modal.error({
            title: 'Request failed',
            content: message,
        })
    }

    const onFinish = async (values: any) => {
        try {
            const resetToken = new URLSearchParams(location.search).get('token')
            if (!resetToken) {
                Modal.error({
                    title: 'Authentication missing',
                    content:
                        'Please go to your email inbox and click the link in the reset mail, or send a new reset-request',
                    onOk: () => {
                        navigate('/forgot-password-revisor/')
                    },
                    onCancel: () => {
                        navigate('/forgot-password-revisor/')
                    },
                })
                return
            }
            await httpPostRevisor<Response>('/auth-revisor/reset-password', {
                token: resetToken,
                password: values.password,
            })
            message.success('Password reset!')
            Modal.success({
                title: 'Password reset!',
                content: 'You can now log in with your new password',
                onOk: () => {
                    navigate('/login-revisor/')
                },
                onCancel: () => {
                    navigate('/login-revisor/')
                },
            })
        } catch (err) {
            if (err.status === 400) {
                error(err.data?.detail)
            } else {
                error(err.message)
            }
        }
    }

    return (
        <div style={{ padding: '10px' }}>
            <p>Input your new password!</p>
            <ChangePasswordForm onSubmit={onFinish} includeExisting={false} />
            <Form>
                <Form.Item>
                    <Link to="/login-revisor/" replace>
                        <Button
                            className="landing-button"
                            type="primary"
                            shape="round"
                            size="small"
                        >
                            Go back to login
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        </div>
    )
}

const ResetPasswordContainer = () => {
    return (
        <div className="login-background">
            <div className="login-background-inner">
                <div className="login-container">
                    <div>
                        <Link to="/" replace>
                            <div className="logoImgLarge" />
                        </Link>
                    </div>
                    <ResetPassword />
                </div>
                <br />
            </div>
        </div>
    )
}

export default ResetPasswordContainer
